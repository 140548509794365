<template>
  <div class="blog-listing">
    <div class="head-title">
      <div class="container">
        <h5 class="color-1">Blog</h5>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div v-if="firstBlog" class="hero-blog row">
            <div class="col-md-4">
              <div class="img-wrap">
                <img
                  v-if="firstBlog && firstBlog.image != null"
                  :src="firstBlog.image"
                  alt=""
                />
                <img v-else src="../assets/thumb.png" alt="" />
              </div>
            </div>
            <div class="blog-body col-md-8">
              <h5 v-if="firstBlog">
                {{ firstBlog.title }}
              </h5>
              <p v-if="firstBlog">
                {{ firstBlog.short_description }}
              </p>
              <div class="info-row">
                <router-link
                  :to="{
                    name: 'BlogSingle',
                    params: { slug: firstBlog.slug },
                  }"
                  class="btn"
                  >read more
                </router-link>
                <!-- <a href="#" class="btn"> </a> -->
              </div>
            </div>
          </div>

          <div v-if="blogLoading" class="loading">Loading...</div>
          <div class="row" v-else-if="blogs">
            <div class="col-md-3" v-for="blog in blogs" :key="blog.id">
              <router-link
                :to="{
                  name: 'BlogSingle',
                  params: { slug: blog.slug },
                }"
                class="blog-card"
              >
                <div class="img-wrap">
                  <img v-if="blog.image != null" :src="blog.image" alt="" />
                  <img v-else src="../assets/thumb.png" alt="" />
                </div>
                <div class="blog-body">
                  <h5>{{ blog.title }}</h5>
                  <div class="info-row">
                    <p class="credit">by {{ blog.author }}</p>
                  </div>
                </div>
              </router-link>
            </div>
            <div class="paging-wrapper py-3">
              <v-pagination
                v-model="blogPage"
                :pages="totalBlogPages"
                :range-size="1"
                active-color="#DCEDFF"
                @update:modelValue="blogPaginateUpdateHandler"
                v-if="totalBlogs > 0"
              />
            </div>
            <EmptyList
              v-if="totalBlogs == 0"
              messageText="No blogs are available!"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CommonService from "@/services/CommonService";
import EmptyList from "@/components/EmptyList.vue";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

export default {
  name: "BlogListing",
  components: {
    EmptyList,
    VPagination,
  },
  data() {
    return {
      blogLoading: true,
      blogPage: 1,
      currentBlogPage: 1,
      totalBlogPages: 0,
      totalBlogs: 0,
      blogs: [],
      firstBlog: null,
    };
  },
  created() {
    this.fetchLatestOneBlog();
    this.fetchAllBlogs();
  },
  methods: {
    async fetchLatestOneBlog() {
      await CommonService.getLatestFirstBlog()
        .then((response) => {
          this.blogLoading = false;
          if (response.data.status === "SUCCESS") {
            this.firstBlog = response.data.blog;
          } else if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          } else if (response.data.status === "VALIDATION_FAILED") {
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async fetchAllBlogs() {
      let params = {
        page: this.currentBlogPage,
      };
      await CommonService.getBlogs(params)
        .then((response) => {
          this.blogLoading = false;
          if (response.data.status === "SUCCESS") {
            this.blogs = response.data.blogs.data;
            this.totalBlogPages = response.data.blogs.last_page;
            this.currentBlogPage = response.data.blogs.current_page;
            this.totalBlogs = response.data.blogs.total;
          } else if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          } else if (response.data.status === "VALIDATION_FAILED") {
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    blogPaginateUpdateHandler(selectedPage) {
      if (this.currentBlogPage !== selectedPage) {
        this.currentBlogPage = selectedPage;
        this.fetchAllBlogs();
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/style/content-page.scss";
</style>
